.navbar{
    height: 60px;
    background:black;
    padding: 0px 10px;
    width: 100%;
    margin: auto;
    border-radius: 2px;
    position: relative;
}

.navbar-white{background: white;}

.navbar-white .nav-logo, 
.navbar-white .hamburger,
.navbar-white .hamburger svg,
.desktop.navbar-white .continents-menu .nav-menu.continent{
    color: black;
}

.navbar-white .nav-logo:hover,
.desktop.navbar-white .continents-menu .nav-menu.continent:hover{
    color: rgba(0,0,0,.75);
}

.navbar-white .spotify-play-btn svg{color: rgba(0,0,0,.75)}

.navbar-white .spotify-play-btn:hover{border: 1px solid #ccc}
.navbar-white .spotify-play-btn{border: 1px solid white;}
.navbar-white .spotify-play-btn:hover svg{color: rgba(0,0,0,.75);}

.nav-menu{
    font-family: "Nunito Black";
    font-size: 17px;
    font-weight: bold;
    color: white;
    text-decoration: none;
    margin: 0px 7px;
    cursor: pointer;
    line-height: 20px;
}

.spotify-play-btn{
    width: fit-content;
    margin-left: auto;
    padding: 10px;
    border-radius: 6px;
    box-sizing: border-box;
    border: 1px solid rgba(0,0,0,.9);
    display: flex;
    justify-content: end;
    cursor: pointer;
    margin-right: 20px;
}

.spotify-play-btn:hover{border: 1px solid #666;color: #ccc;}
.spotify-play-btn:hover svg{color: #ccc;}
.spotify-play-btn svg{color: white;}

.spotify-player{
    position: fixed;
    top: 70px;
    right: -100%;
    transition: right 0.3s ease-in-out;

    width: 400px;
    z-index: 10;

    background: rgba(0,0,0,.9);
    padding: 30px;
    padding-top: 10px;
    border-radius: 12px;
}

.spotify-player iframe{height: 500px;}
.spotify-player.enter-left {right: 10px; /* Slide in to visible area */}
.spotify-player.exit-right {right: -100%; /* Slide out back to off-screen */}

.close-spotify-player-btn{
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
    cursor: pointer;

    width: fit-content;
    margin-left: auto;
    padding: 10px;
    border-radius: 6px;
    box-sizing: border-box;
    border: 1px solid rgba(0,0,0,.9);

}

.close-spotify-player-btn:hover{border: 1px solid #666;}
.close-spotify-player-btn:hover svg{border: #666;}
.close-spotify-player-btn svg{color: white}

@media(min-width: 500px) and (max-width: 768px){
    .spotify-player{
        width: 350px;
        padding: 20px;
        padding-top: 0px;
    }

    .spotify-player iframe{height: 500px !important;}
    .spotify-play-btn{margin-right: 0px;}
    .close-spotify-player-btn{margin-bottom: 0px;}
    .spotify-player.enter-left {right: 2.5% /* Slide in to visible area */}
}

@media(max-width: 500px){
    .spotify-player{
        width: 325px;
        padding: 20px;
        padding-top: 0px;
        padding-bottom: 40px;
    }

    .spotify-player iframe{
        height: 400px;
    }

    .spotify-play-btn{
        margin-right: 0px;
    }

    .close-spotify-player-btn{
        margin-bottom: 0px;
    }

    .spotify-player.enter-left {
        right: 10px; /* Slide in to visible area */
    }
}


.nav-logo{font-family: "Edo";font-weight: 100;}
.nav-menu:hover{color: #ccc}

.hamburger{display: flex;align-items: center;justify-content: center;}

@media(min-width: 769px){
    .desktop-menu{
        position: absolute;
        top: 40px;

        align-items: center;
        column-gap: 5px;
        text-align: left;

        background: rgba(0,0,0,.95);
        box-shadow: 0px 0px 15px black;

        width: 500px;
        height: 80vh;
        padding: 20px 0px 20px 20px;

        overflow-y: auto;
        z-index: 15;

        border-bottom: 1px solid #666;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }

    .nav-logo{font-size: 24px;}

    .asia-menu{left: -210px;}
    .southamerica-menu{left: -120px}
    .europe-menu{left: 30px}
    .middleeast-menu{left: 100px}

    .desktop-menu.d-flex{display: block !important;}
    .desktop-menu .inner-menu{ display: flex;}
    .mobile-menu.mobile, .mobile-menu{display: none !important;}
}

@media(max-width: 768px){
    .navbar{border-radius: 0px;}

    .mobile-menu.desktop{display: none !important;}

    .mobile-menu{
        position: fixed;
        background: rgba(0,0,0,.95);

        display: block;
        align-items: flex-start;
        width: 95%;
        padding: 20px 0px 20px 20px;
        text-align: left;
        column-gap: 5px;
        z-index: 15;
        box-shadow: 0px 0px 15px black;

        height: 100vh;
        overflow-y: scroll;
        transition: right .4s ease;
    }

    .main-mobile-menu .nav-menu{margin: 25px 0px;}
    .nav-menu:hover{color: white;}
    .nav-logo{font-size: 23px;}
    .hamburger:hover{color: #eee;}

    .mobile-menu .inner-menu{
        width: 100%;
        display: flex;
        overflow-y: scroll;
        row-gap: 15px;
        padding-top: 20px;
        z-index: 16;
    }

    .mobile-menu.asia-menu,
    .mobile-menu.southamerica-menu,
    .mobile-menu.europe-menu,
    .mobile-menu.middleeast-menu{
        line-height: 20px;
        flex-direction: column;
        align-items: baseline;
        row-gap: 22px;
        flex-wrap: wrap;
        z-index: 16;
        width: 95%;
    }

    .mobile-menu #home-categories{
        position: relative !important;
        padding-right: 20px;
        background: rgba(0,0,0,0);
        height: 100vh;
    }

    .mobile-menu #home-categories .inner-categories{
        background: rgba(0,0,0,0);
        padding-top: 0px;
        row-gap: 16px;
    }

    .mobile-menu #home-categories .inner-categories h2{
        color: white;
        margin-top: 0px !important;
        font-size: 20px;
    }
}