@media(min-width: 1401px) {.App {margin: auto;}}

.App {text-align: center;}

.App-logo {height: 40vmin;pointer-events: none;}

.App-link {color: #61dafb;}



/* 
oooooooooooo                           .            
`888'     `8                         .o8            
 888          .ooooo.  ooo. .oo.   .o888oo  .oooo.o 
 888oooo8    d88' `88b `888P"Y88b    888   d88(  "8 
 888    "    888   888  888   888    888   `"Y88b.  
 888         888   888  888   888    888 . o.  )88b 
o888o        `Y8bod8P' o888o o888o   "888" 8""888P' 
*/

@font-face {
  font-family: "Edo";
  src: local("edo"),
    url("./assets/fonts/Edo/edo.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito";
  src: local("Nunito"),
    url("./assets/fonts/Nunito/Nunito-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito Light";
  src: local("Nunito"),
    url("./assets/fonts/Nunito/static/Nunito-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito Regular";
  src: local("Nunito"),
    url("./assets/fonts/Nunito/static/Nunito-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito Medium";
  src: local("Nunito"),
    url("./assets/fonts/Nunito/static/Nunito-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito Semi Bold";
  src: local("Nunito"),
    url("./assets/fonts/Nunito/static/Nunito-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito Bold";
  src: local("Nunito"),
    url("./assets/fonts/Nunito/static/Nunito-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito Extra Bold";
  src: local("Nunito"),
    url("./assets/fonts/Nunito/static/Nunito-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito Black";
  src: local("Nunito"),
    url("./assets/fonts/Nunito/static/Nunito-Black.ttf") format("truetype");
}




/* 
  .oooooo.    oooo             .o8                 oooo  
 d8P'  `Y8b   `888            "888                 `888  
888            888   .ooooo.   888oooo.   .oooo.    888  
888            888  d88' `88b  d88' `88b `P  )88b   888  
888     ooooo  888  888   888  888   888  .oP"888   888  
`88.    .88'   888  888   888  888   888 d8(  888   888  
 `Y8bood8P'   o888o `Y8bod8P'  `Y8bod8P' `Y888""8o o888o 
*/

/* Apply box-sizing to all elements */
*, *::before, *::after {box-sizing: border-box;}

html, body { box-sizing: border-box; }

h1 {font-family: "Nunito Black"; margin: .5em 0px;}
h2 {font-family: "Nunito Black";font-size: 26px}

.bold {font-weight: bold;}
.text-center {text-align: center;}

.pos-rel {position: relative;}

.flex-col {flex-direction: column;}
.nowrap {flex-wrap: nowrap;}
.justify-center {justify-content: center;}
.justify-start {justify-content: start !important;}
.space-between {justify-content: space-between;}
.align-center {align-items: center;}

.col-gap-5 {column-gap: 5px;}
.col-gap-10 {column-gap: 10px;}
.col-gap-12 {column-gap: 12px;}
.row-gap-5 {row-gap: 5px;}

.w-45 {width: 45%;}
.w-50 {width: 50%;}
.w-55 {width: 55%;}
.w-80 {width: 80%;}
.w-90 {width: 90%;}
.w-95 {width: 95%;}


.mt-5 {margin-top: 5px;}
.mt-10 {margin-top: 10px;}
.mt-15 {margin-top: 15px;}
.mt-20 {margin-top: 20px;}
.mt-25 {margin-top: 25px;}
.mb-5 {margin-bottom: 5px;}
.mb-10 {margin-bottom: 10px;}
.mb-15 {margin-bottom: 15px;}
.mr-5 {margin-right: 5px;}
.mx-2 {margin-left: 2px;margin-right: 2px} 
.mx-3 {margin-left: 3px;margin-right: 3px}
.my-5 {margin-top: 5px;margin-bottom: 5px;}
.my-10 {margin-top: 10px;margin-bottom: 10px;}



.home .continents-wrapper,
.home-category-page .continents-wrapper {
  margin-bottom: 2px;
}

.cities-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  width: 100%;

  row-gap: 16px;
  column-gap: 16px;

  margin: auto;
  margin-bottom: 10px;
}

.categories-wrapper.categories-row {
  width: 100%;
  margin: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
  z-index: 3;
}

.inner-categories {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 16px;
  margin: auto;
}

.category-title {
  position: sticky;

  width: 100%;
  top: 0px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  background: rgba(0,0,0,1);
  padding: 25px 0px;
  z-index: 4;

  /* border: 4px solid black;
  box-shadow: 6px 6px 0px 0px black; */

}

.category-title h2 {
  margin: 0px;
  color: white;
}

.total-videos {
  font-size: 12px;
}

.total-vids-margin{
  margin-right: 3px;
}

.cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 2px;
  row-gap: 2px;
  margin: 0px 0px 0px 0px;
}

.black-bar-title h2 {
  font-family: "Edo";
  font-weight: 100;
  margin: 0px;
}

/* 
                   o8o                                        o8o        .o8      .   oooo        
                   `"'                                        `"'       "888    .o8   `888        
ooo. .oo.  .oo.   oooo  ooo. .oo.           oooo oooo    ooo oooo   .oooo888  .o888oo  888 .oo.   
`888P"Y88bP"Y88b  `888  `888P"Y88b           `88. `88.  .8'  `888  d88' `888    888    888P"Y88b  
 888   888   888   888   888   888  8888888   `88..]88..8'    888  888   888    888    888   888  
 888   888   888   888   888   888             `888'`888'     888  888   888    888 .  888   888  
o888o o888o o888o o888o o888o o888o             `8'  `8'     o888o `Y8bod88P"   "888" o888o o888o 
                                                                                                  
                                                                                                  
                                                                                                  
 ooooooooo     .ooo    .ooooo.                                                                    
d"""""""8'   .88'     888' `Y88.                                                                  
      .8'   d88'      888    888 oo.ooooo.  oooo    ooo                                           
     .8'   d888P"Ybo.  `Vbood888  888' `88b  `88b..8P'                                            
    .8'    Y88[   ]88       888'  888   888    Y888'                                              
   .8'     `Y88   88P     .88P'   888   888  .o8"'88b                                             
  .8'       `88bod8'    .oP'      888bod8P' o88'   888o                                           
                                  888                                                             
                                 o888o                                                                        
*/


@media(min-width: 769px) {
  .mobile {
    display: none !important;
  }

  .cards-wrapper {
    padding: 0px 1px;
  }

  .category-title h2 {
    font-family: "Edo";
    font-weight: 100;
    font-size: 30px;
  }

  .cities-wrapper {
    padding: 0px 1px;
    margin-bottom: 20px;
  }

  .inner-cities {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: auto;
    column-gap: 10px;
    row-gap: 10px;
    margin-bottom: 0px;
    width: 100%;
  }

  .hp-quick-links {
    padding-bottom: 0px;
    border-bottom: 0px solid #ccc;
    margin-bottom: 25px;
    border-top: 0px solid #ccc;
  }

  .category-title .subtitle {
    font-family: "Edo";
    font-weight: 100;
    font-size: 19px;
    margin-bottom: -3px;
    margin-top: -10px;
    color: #666;
  }

  .breadcrumb-page-back{
    font-size: 15px;
  }

}

/*                                                                                                                                        
8888888 8888888 8888888 8888888 8888888 8888888 8888888 8888888 8888888 8888888 
*/



/* 
                                                                  o8o        .o8      .   oooo        
                                                                  `"'       "888    .o8   `888        
ooo. .oo.  .oo.    .oooo.   oooo    ooo         oooo oooo    ooo oooo   .oooo888  .o888oo  888 .oo.   
`888P"Y88bP"Y88b  `P  )88b   `88b..8P'           `88. `88.  .8'  `888  d88' `888    888    888P"Y88b  
 888   888   888   .oP"888     Y888'    8888888   `88..]88..8'    888  888   888    888    888   888  
 888   888   888  d8(  888   .o8"'88b              `888'`888'     888  888   888    888 .  888   888  
o888o o888o o888o `Y888""8o o88'   888o             `8'  `8'     o888o `Y8bod88P"   "888" o888o o888o 
                                                                                                      
                                                                                                      
                                                                                                      
 ooooooooo     .ooo    .ooooo.                                                                        
d"""""""8'   .88'     d88'   `8.                                                                      
      .8'   d88'      Y88..  .8' oo.ooooo.  oooo    ooo                                               
     .8'   d888P"Ybo.  `88888b.   888' `88b  `88b..8P'                                                
    .8'    Y88[   ]88 .8'  ``88b  888   888    Y888'                                                  
   .8'     `Y88   88P `8.   .88P  888   888  .o8"'88b                                                 
  .8'       `88bod8'   `boood8'   888bod8P' o88'   888o                                               
                                  888                                                                 
                                 o888o                                                                                                                                                                  
*/


@media(max-width: 768px) {
  .desktop {display: none !important;}

  .black-bar-title h2 {font-size: 26px;}

  .black-bar-title svg {color: black;}

  .hp-quick-links{overflow-y: scroll;}

  .home .mobile-menu {flex-direction: column;align-items: baseline;}

  .category-title{
    padding: 15px 0px;
  }

  .category-title .subtitle {
    font-family: "Edo";
    font-weight: 100;
    font-size: 15px;
    margin-bottom: -3px;
    margin-top: -5px;
    color: #666;
  }

  .home .category-title h2,
  .category-title h2 {
    font-family: "Edo";
    font-weight: 100;
    font-size: 28px;

    white-space: nowrap;
    overflow: hidden;
  }

  /* Hide Cities on City Page and City Category Page on Mobile */
  .city-page .cities-wrapper, .category-page .cities-wrapper{
    display: none;
  }

  .cities-wrapper {
    margin-bottom: 0px;
    padding:0px 10px;
    overflow-x: scroll;
    /* background-image: radial-gradient(#80808080 1px,#0000 0);
    background-size: 16px 16px; */
  }

  .hongkong .cities-wrapper, 
  .singapore .cities-wrapper,
  .thephilippines .cities-wrapper,
  .laos .cities-wrapper, 
  .cambodia .cities-wrapper,
  .nepal .cities-wrapper,
  .bhutan .cities-wrapper, 
  .mongolia .cities-wrapper,
  .bangladesh .cities-wrapper,
  .srilanka .cities-wrapper,
  .india .cities-wrapper,

  .newyork .cities-wrapper,
  .sanfrancisco .cities-wrapper,

  .puertorico .cities-wrapper,
  .costarica .cities-wrapper,
  .cuba .cities-wrapper,
  .ecuador .cities-wrapper,
  .chile .cities-wrapper,

  .amsterdam .cities-wrapper, 
  .prague .cities-wrapper,
  .estonia .cities-wrapper,
  .iceland .cities-wrapper, 
  .greece .cities-wrapper,
  .london .cities-wrapper,
  .romania .cities-wrapper, 

  .egypt.cities-wrapper{
    display: none;
  }


  .inner-cities {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: nowrap;

    column-gap: 12px;
    row-gap: 12px;
    max-width: 100%;
    width: 100%;

    margin-top: 5px;
    padding-bottom: 10px;
  }

  .home .cities-wrapper {
    padding-bottom: 0px;
    margin: 0px 0px;
  }

  .breadcrumbs-and-videos {
    position: relative;
    line-height: 20px;
  }

  .total-videos {text-wrap: nowrap;}
  .home .total-videos {padding-top: 0px;padding-right: 0px;}

  .home .categories-wrapper {margin-bottom: 20px;margin-top: 20px;}
  .categories-wrapper {
    padding: 5px 10px 10px 10px;
    margin-bottom: 5px;
  }

  .city-page .categories-wrapper{
    padding-top: 10px;
  }

  .categories-wrapper.categories-row {
    margin: 0px auto;
    margin-bottom: 5px;
    /* background-image: radial-gradient(#80808080 1px, transparent 0);
    background-size: 16px 16px; */
    overflow-x: scroll;
  }

  .hp-quick-links,
  .hp-quick-links .inner-categories {
    background: white;
    flex-wrap: wrap !important;
  }

  .categories-wrapper.hp-quick-links {
    border-top: 0px solid #ccc;
    border-bottom: 0px solid #ccc;
  }

  .categories-wrapper.hp-quick-links a {
    font-size: 19px;
    width: 97%;
    height: 65px;

    border: 2px solid black;
    border-radius: 0px !important;
    box-shadow: 6px 6px 0px 0px black;

    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s;
  }

  .categories-wrapper.hp-quick-links.shortcuts a {
  border: 0px solid #f1f1f1 !important;
  box-shadow: 0px 0px 5px rgba(255,255,255,.5) !important;
  }

  .inner-categories {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    column-gap: 5px;
    row-gap: 13px;
    max-width: 100%;
    width: 100%;
    /* min-width: 500px; */
  }

  .hp-quick-links .inner-categories {justify-content: start !important;}

  .home .inner-categories,
  .home.category-page .inner-categories {padding: 0px 0px;}

  .home .inner-categories,
  .home-category-page .inner-categories {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 5px;
    row-gap: 16px;
    max-width: 1300px;
    margin: auto;
  }

  .categories-row .inner-categories {
    justify-content: start;
    column-gap: 8px;
    row-gap: 8px;
  }
  

  .home .categories-row .inner-categories {
    justify-content: start;
  }


  .home .hp-quick-links .inner-categories,
  .home-category-page .hp-quick-links .inner-categories, 
  .hp-quick-links .inner-categories {
    padding: 0px 10px;
    height: auto;
    margin-top: 20px;
    row-gap: 16px;
  }

  .hp-quick-links .inner-categories{margin-bottom: 200px;}

  .cards-wrapper {margin: 0px;}

}


/*                                                                                                                                        
8888888 8888888 8888888 8888888 8888888 8888888 8888888 8888888 8888888 8888888 
*/







hr {border-top: 1px solid #eee;}

.page-back {
  text-align: left;
  font-family: "Nunito Black";
  font-size: 17px;
  background: white;
  width: 100%;
}

.page-back svg {color: black}

.page-back a {
  display: flex;
  text-decoration: none;
  color: black;
}

.page-back a:hover,
.page-back a:hover svg{
  color: #666;
}

.d-flex {display: flex;}
.d-none {display: none;}


@media(max-width: 500px) {.categories-wrapper.hp-quick-links a {margin: auto; }}