.breadcrumbs{
    font-size: 13px;
    margin: 10px;
  }
  
  .breadcrumbs a{
    color: black;
    text-decoration: none;
  }
  
  .breadcrumbs a:hover{
    color: blue;
    text-decoration: underline;
  }

  .breadcrumbs-and-videos{
    display:flex; 
    justify-content: space-between;
    align-items: center;
  }

  @media(min-width: 769px){
    .breadcrumbs{
      margin: 0px 10px;
    }

    .breadcrumbs-and-videos{
      margin: 5px 10px 5px 0px;
      line-height: 20px;
    }

    .breadcrumb-page-back{
      margin: 5px 12px 0px 4px
    }
  
  }

  @media(max-width: 768px){
    .breadcrumbs{
      margin: 0px;
      overflow: hidden;
      margin-right: 10px;
    }
    .breadcrumbs-and-videos{
      padding: 5px 8px 6px 8px;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
    }

    .home .breadcrumbs-and-videos{
      border-top: 0px solid #ccc;
      border-bottom: 0px solid #ccc;
      margin-top: 0px;
    }

    .breadcrumb-page-back{
      padding: 5px 8px 0px 0px;
    }

    .breadcrumbs-and-videos{
      margin-top: 5px;
    }

  }