@media (min-width: 1441px){
    .home-banner, .page-banner {
        height: 400px;
    }
}

@media (min-width: 1025px) and (max-width: 1440px){
    .home-banner, .page-banner {
        height: 325px;
    }
}

@media (min-width: 769px) and (max-width: 1024px){
    .home-banner, .page-banner {
        height: 325px;
    }

    .banner-title{
        font-size: 3em;
    }

    .banner-subtitle{
        font-size: 1.5em;
    }

    .banner-description{
        font-size: 1em;
    }

}

.home-banner, .page-banner {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    background-size: cover; /* Ensure the image covers the entire div */
    background-position-y: 40%;
    background-repeat: no-repeat; /* Prevent the image from repeating */

    color: white;
    margin: auto;

    border-bottom: 3px solid black;

    /* box-shadow: 4px 4px 0px 0px black; */

}

/* Optional: Style any content inside the banner */
.home-banner .banner-content {
    text-align: center;
    padding: 20px;
}

.banner-title{
    font-family: "Edo";
    font-size: 4em;
    font-weight: 100;
}

.home-banner .banner-title{
    margin-top: 0px;
}

.banner-subtitle{
    font-family: "Nunito Black";
    font-size: 2em;
    font-weight: 100;
    margin-bottom: 0px;
}

.banner-description{
    font-family: "Nunito Black";
    font-size: 1.5em;
    font-weight: 100;
    margin-top: 0px;
}

.banner-title, .banner-subtitle, .banner-description{
    text-shadow: 1px 1px 0 #000,1px 1px 5px #000;
}

.banner-text {
    position: relative; /* Ensure text is positioned relative to its container */
    z-index: 1; /* Ensure text appears above the gradient background */
}

@media (min-width: 769px) and (max-width: 900px){
    .banner-title{
        font-size: 50px;
    }

    .banner-subtitle{
        font-size: 28px;
    }
}

@media(min-width: 769px){
    .home-banner, .page-banner {
        margin-bottom: 15px;
    }
}

@media(max-width: 768px){

    .home-banner{
        height: 250px;
        width: calc(100% - 8px);

        border-top: 6px solid black;
        border: 6px solid black;
        box-shadow: 8px 8px 0px 0px black;

        margin-top: 5px;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 10px;
    }

    .page-banner {
        height: 250px;
        width: calc(100% - 18px);

        border-top: 6px solid black;
        border: 6px solid black;
        box-shadow: 8px 8px 0px 0px black;

        margin-top: 5px;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 15px;
    
    }

    .banner-title{
        font-size: 3em;
    }

    .banner-subtitle{
        font-size: 1.5em;
    }

    .banner-description{
        font-size: 1em;
    }
}

@media(max-width: 662px){
    .mobile-line-break{
        display: block;
    }
}




@media(max-width: 768px) and (min-width: 501px){
    .home-banner{
        margin-bottom: 20px;
        width: calc(100% - 18px);
    }
}


@media(max-width: 500px){

    .home-banner .banner-content{
        padding:15px 0px;
    }

    .home-banner{
        height: 225px;
        width: 100%;

        margin: 0px;
        margin-bottom: 10px;

        border-radius: 0px;
        border: 0px solid black;
        box-shadow: 0px 0px 5px black;
    }

    .page-banner{
        height: 200px;
        width: 100%;

        margin: 0px;
        margin-bottom: 10px;

        border-radius: 0px;
        border: 0px solid black;
        box-shadow: 0px 0px 5px black;
    }

    .city-page .page-banner{
        margin-bottom: 5px;
    }

    .banner-title{
        font-size: 36px;
    }

    .mobile-line-break{
        display: block;
    }

    .banner-subtitle{
        font-size: 20px;
        line-height: 26px;
    }

    .banner-description{
        font-size: .9em;
    }
}