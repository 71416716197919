.quicklinks-countries{
    position: fixed;
    top: 0;
    width: 100%;
    background: rgba(0,0,0,.95);
    z-index: 10;
}

.quicklinks-countries, 
.hp-quick-links.standalone{
    transition: right .4s ease;
}

.hp-quick-links.standalone{
    width: 95%;
}

.off-screen.right{
    width: 95%;
    right: 0;
}

.off-screen{
    right: -100%;
}

.hp-quick-links{
    width: 100%;
}

.quicklinks-countries .mobile-menu{
    position: relative;
    padding-left: 10px;
}

.quicklinks-countries .inner{
    width: 100%;
    height: 100vh;
    margin-top: 50px;
    padding: 0px 10px;

    overflow-y: scroll;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 300px;
}

.countries-menu{
    font-family: "Nunito Black";
    font-size: 17px;
    font-weight: bold;
    color: white;
    text-decoration: none;
    /* margin: 0px 7px; */
    padding: 10px 10px;
    cursor: pointer;
    width: 100%;
    text-align: left;
}

.hp-quick-links.d-flex,
.quick-links-countries.d-flex{
    position: fixed !important;
    background: white;
    height: 100vh;
    top: 0px;
    z-index: 16;
    margin-top: 0px !important;
}

.categories-wrapper.hp-quick-links a{
    border: 0px solid #ccc;
}

#close-quick-links{
    position: absolute;
    color: white;
    left: 10px;
    top: 10px !important;
    width: 35px;
    height: 35px;
    background: rgba(0, 0, 0, 0);
    box-shadow: 0px 0px 0px black;
}

.hp-quick-links #close-quick-links{
    left: auto;
    right: 10px;
}

.quick-links-btn-mobile, .quick-links-countries-btn-mobile{
    width: calc(50% - 18px);
    background: black;

    padding: 10px 15px !important;

    display: inline-block !important;
    cursor: pointer;

    background: black;

    box-shadow: 0px 2px 4px #666;
    border-radius: 3px;

    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s;
}

.quick-links-btn-mobile h2, .quick-links-countries-btn-mobile h2{
    font-family: "Nunito Black";
    font-weight: 100;
    font-size: 18px;
    color: white;
    margin: 0px;
}

.quick-links-btn-mobile:hover, .quick-links-countries-btn-mobile:hover{
    transform: translate(1px, 1px);
}

.quick-links-btn-mobile:active, .quick-links-countries-btn-mobile:active{
    box-shadow: 0px 0px 0px black;
    transform: translate(1px, 1px);
}

.hp-quick-links h2{
    font-family: "Nunito Black";
    font-weight: 100;
    font-size: 26px;
    margin-bottom: 5px;
    margin-top: 0px;
}

.quicklinks-countries #home-categories{
    position: relative !important;
    background: rgba(0,0,0,0);
    overflow: visible;
}

.quicklinks-countries #home-categories .inner-categories{
    background: rgba(0,0,0,0);
    padding-top: 0px;
    row-gap: 16px;
}

.quicklinks-countries #home-categories .inner-categories h2{
    color: white;
    margin-top: 0px !important;
    font-size: 20px;
}