.pagination{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
    margin: 20px auto 150px auto;
    max-width: 95%;
  }

  .next-buttons{
    display: flex;
    align-items: center;
  }

  .total-pages{
    font-size: 13px;
    margin: 0px 15px;
  }
  
  .pagination .pages{
    font-size: 13px;
  }
  
  .page-numbers{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: auto;
  }
  
  .page-number{
    font-family: "Nunito Black";
    font-size: 12px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    cursor: pointer;
  }
  
  .page-number:hover{
    transform: scale(1.04);
    background: #eee;
  }
  
  .page-number:active{
    transform: scale(.99);
  }
  
  .page-number.disabled, .page-number.active{
    background: black;
    color: white;
  }