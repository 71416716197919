.background-img{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position-y: 46% !important;
    color: white;
}


/* .background-img:before{
    background: rgba(0, 0, 0, .1);
    border-radius: 6px;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
} */

.asia-img {background-image: url('../assets/img/banners/asia-banner.jpeg') !important;}
.southamerica-img {background-image: url('../assets/img/banners/southamerica-banner.jpeg') !important;}
.europe-img {background-image: url('../assets/img/banners/europe-banner.jpeg') !important; background-position-y: 60% !important;}
.middleeast-img {background-image: url('../assets/img/banners/middleeast-banner.jpeg') !important;}

.bali-img {background-image: url('../assets/img/banners/bali-banner.jpeg') !important;}
.canggu-img {background-image: url('../assets/img/banners/canggu-banner.jpeg') !important;}
.ubud-img {background-image: url('../assets/img/banners/ubud-banner.jpeg') !important;}

.thailand-img {background-image: url('../assets/img/banners/thailand-banner.jpeg') !important;}
.bangkok-img {background-image: url('../assets/img/banners/bangkok-banner.jpeg') !important; background-position-y: 0% !important;}
.chiangmai-img {background-image: url('../assets/img/banners/chiangmai-banner.jpeg') !important;}
.phuket-img {background-image: url('../assets/img/banners/phuket-banner.jpeg') !important; background-position-y: 63% !important;}
.kosamui-img {background-image: url('../assets/img/banners/kosamui-banner.jpeg') !important; background-position-y: 67% !important;}
.kophangan-img {background-image: url('../assets/img/banners/kophangan-banner.jpeg') !important;}

.japan-img {background-image: url('../assets/img/banners/japan-banner.jpeg') !important;background-position-y: 88% !important;}
.tokyo-img {background-image: url('../assets/img/banners/tokyo-banner.jpeg') !important; background-position-y: 67% !important;}
.osaka-img {background-image: url('../assets/img/banners/osaka-banner.jpeg') !important; background-position-y: 78% !important;}
.kyoto-img {background-image: url('../assets/img/banners/kyoto-banner.jpeg') !important; background-position-y: 45% !important;}

.vietnam-img {background-image: url('../assets/img/banners/vietnam-banner.jpeg') !important;}
.hanoi-img {background-image: url('../assets/img/banners/hanoi-banner.jpeg') !important;}
.hochiminhcity-img {background-image: url('../assets/img/banners/hochiminhcity-banner.jpeg') !important; background-position-y: 19% !important;}

.korea-img {background-image: url('../assets/img/banners/korea-banner.jpeg') !important;}
.seoul-img {background-image: url('../assets/img/banners/seoul-banner.jpeg') !important; background-position-y: 67% !important;}

.taiwan-img {background-image: url('../assets/img/banners/taiwan-banner.jpeg') !important; background-position-y: 80% !important;}
.taipei-img {background-image: url('../assets/img/banners/taipei-banner.jpeg') !important;}

.china-img {background-image: url('../assets/img/banners/china-banner.jpeg') !important;}
.beijing-img {background-image: url('../assets/img/banners/beijing-banner.jpeg') !important;}
.shanghai-img {background-image: url('../assets/img/banners/shanghai-banner.jpeg') !important;}

.hongkong-img {background-image: url('../assets/img/banners/hongkong-banner.jpeg') !important; background-position-y: 60% !important;}
.singapore-img {background-image: url('../assets/img/banners/singapore-banner.jpeg') !important; background-position-y: 49% !important;}

.indonesia-img {background-image: url('../assets/img/banners/indonesia-banner.jpeg') !important;}
.jakarta-img {background-image: url('../assets/img/banners/jakarta-banner.jpeg') !important;}

.malaysia-img {background-image: url('../assets/img/banners/malaysia-banner.jpeg') !important;}
.kualalumpur-img {background-image: url('../assets/img/banners/kualalumpur-banner.jpeg') !important;}
.borneo-img {background-image: url('../assets/img/banners/borneo-banner.jpeg') !important;}

.myanmar-img {background-image: url('../assets/img/banners/myanmar-banner.jpeg') !important; background-position-y: 100% !important;}
.yangon-img {background-image: url('../assets/img/banners/yangon-banner.jpeg') !important; background-position-y: 100% !important;}
.bagan-img {background-image: url('../assets/img/banners/bagan-banner.jpeg') !important; background-position-y: 25% !important;}

.thephilippines-img {background-image: url('../assets/img/banners/thephilippines-banner.jpeg') !important;}
.laos-img {background-image: url('../assets/img/banners/laos-banner.jpeg') !important;}
.cambodia-img {background-image: url('../assets/img/banners/cambodia-banner.jpeg') !important; background-position-y: 30% !important;}

.bangladesh-img {background-image: url('../assets/img/banners/bangladesh-banner.jpeg') !important; background-position-y: 85% !important;}
.srilanka-img {background-image: url('../assets/img/banners/srilanka-banner.jpeg') !important;}
.india-img {background-image: url('../assets/img/banners/india-banner.jpeg') !important; background-position-y: 30% !important;}

.nepal-img {background-image: url('../assets/img/banners/nepal-banner.jpeg') !important;}
.bhutan-img {background-image: url('../assets/img/banners/bhutan-banner.jpeg') !important;}
.mongolia-img {background-image: url('../assets/img/banners/mongolia-banner.jpeg') !important; background-position-y: 85% !important;}


.newyork-img {background-image: url('../assets/img/banners/newyork-banner.jpeg') !important; background-position-y: 82% !important;}
.sanfrancisco-img {background-image: url('../assets/img/banners/sanfrancisco-banner.jpeg') !important; background-position-y: 55% !important;}


.brazil-img {background-image: url('../assets/img/banners/brazil-banner.jpeg') !important;}
.riodejaneiro-img {background-image: url('../assets/img/banners/riodejaneiro-banner.jpeg') !important;}

.colombia-img {background-image: url('../assets/img/banners/colombia-banner.jpeg') !important;}
.medellin-img {background-image: url('../assets/img/banners/medellin-banner.jpeg') !important;}
.bogota-img {background-image: url('../assets/img/banners/bogota-banner.jpeg') !important;}
.cartagena-img {background-image: url('../assets/img/banners/cartagena-banner.jpeg') !important; background-position-y: 75% !important;}

.mexico-img {background-image: url('../assets/img/banners/mexico-banner.jpeg') !important; background-position-y: 73% !important;}
.mexicocity-img {background-image: url('../assets/img/banners/mexicocity-banner.jpeg') !important; background-position-y: 83% !important;}
.playadelcarmen-img {background-image: url('../assets/img/banners/playadelcarmen-banner.jpeg') !important;}
.tulum-img {background-image: url('../assets/img/banners/tulum-banner.jpeg') !important;}

.costarica-img {background-image: url('../assets/img/banners/costarica-banner.jpeg') !important;}
.cuba-img {background-image: url('../assets/img/banners/cuba-banner.jpeg') !important;}
.ecuador-img {background-image: url('../assets/img/banners/ecuador-banner.jpeg') !important;}
.chile-img {background-image: url('../assets/img/banners/chile-banner.jpeg') !important;}
.puertorico-img {background-image: url('../assets/img/banners/puertorico-banner.jpeg') !important;}

.peru-img {background-image: url('../assets/img/banners/peru-banner.jpeg') !important;}
.lima-img {background-image: url('../assets/img/banners/lima-banner.jpeg') !important;}

.argentina-img {background-image: url('../assets/img/banners/argentina-banner.jpeg') !important;}
.buenosaires-img {background-image: url('../assets/img/banners/buenosaires-banner.jpeg') !important;}




.portugal-img {background-image: url('../assets/img/banners/portugal-banner.jpeg') !important;}
.lisbon-img {background-image: url('../assets/img/banners/lisbon-banner.jpeg') !important;}
.madeira-img {background-image: url('../assets/img/banners/madeira-banner.jpeg') !important;}

.italy-img {background-image: url('../assets/img/banners/italy-banner.jpeg') !important; background-position-y: 67% !important;}
.rome-img {background-image: url('../assets/img/banners/rome-banner.jpeg') !important;}
.venice-img {background-image: url('../assets/img/banners/venice-banner.jpeg') !important;}

.spain-img {background-image: url('../assets/img/banners/spain-banner.jpeg') !important;}
.barcelona-img {background-image: url('../assets/img/banners/barcelona-banner.jpeg') !important;}
.madrid-img {background-image: url('../assets/img/banners/madrid-banner.jpeg') !important;}

.germany-img {background-image: url('../assets/img/banners/germany-banner.jpeg') !important; background-position-y: 85% !important;}
.berlin-img {background-image: url('../assets/img/banners/berlin-banner.jpeg') !important;}

.france-img {background-image: url('../assets/img/banners/france-banner.jpeg') !important; background-position-y: 67% !important;}
.paris-img {background-image: url('../assets/img/banners/paris-banner.jpeg') !important;}

.poland-img {background-image: url('../assets/img/banners/poland-banner.jpeg') !important;}
.warsaw-img {background-image: url('../assets/img/banners/warsaw-banner.jpeg') !important;}
.krakow-img {background-image: url('../assets/img/banners/krakow-banner.jpeg') !important;}

.iceland-img {background-image: url('../assets/img/banners/iceland-banner.jpeg') !important;}
.amsterdam-img {background-image: url('../assets/img/banners/amsterdam-banner.jpeg') !important;}
.greece-img {background-image: url('../assets/img/banners/greece-banner.jpeg') !important;}
.prague-img {background-image: url('../assets/img/banners/prague-banner.jpeg') !important;}
.london-img {background-image: url('../assets/img/banners/london-banner.jpeg') !important;}
.estonia-img {background-image: url('../assets/img/banners/estonia-banner.jpeg') !important;}

.croatia-img {background-image: url('../assets/img/banners/croatia-banner.jpeg') !important;}
.zagreb-img {background-image: url('../assets/img/banners/zagreb-banner.jpeg') !important;}

/* .russia-img {background-image: url('../assets/img/banners/russia-banner.jpeg') !important;}
.moscow-img {background-image: url('../assets/img/banners/moscow-banner.jpeg') !important;} */

.romania-img {background-image: url('../assets/img/banners/romania-banner.jpeg') !important;}


.uae-img {background-image: url('../assets/img/banners/uae-banner.jpeg') !important;}
.dubai-img {background-image: url('../assets/img/banners/dubai-banner.jpeg') !important; background-position-y: 80% !important;}
.egypt-img {background-image: url('../assets/img/banners/egypt-banner.jpeg') !important;}
.turkey-img {background-image: url('../assets/img/banners/turkey-banner.jpeg') !important; background-position-y: 55% !important;}
.istanbul-img {background-image: url('../assets/img/banners/istanbul-banner.jpeg') !important; background-position-y: 36% !important;}
.israel-img {background-image: url('../assets/img/banners/israel-banner.jpeg') !important;}
.telaviv-img {background-image: url('../assets/img/banners/telaviv-banner.jpeg') !important;}




/* .-img {background-image: url('../assets/img/banners/-banner.jpeg') !important;} */