button {
    cursor: pointer;
}

.card-button:hover {transform: scale(1.06);}
.card-button:active {transform: scale(.98);}

.cities-wrapper a{
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    font-family: "Nunito Black";
    font-size: 18px;
    font-weight: bold;

    color: white;

    width: calc(16.66% - 16px);
    height: 70px;

    text-decoration: none;

    text-shadow: 1px 1px 0 #000, 1px 1px 5px #000;
    text-transform: uppercase;
    
    border: 0px solid black;
    border-radius: 1px;
    box-shadow: 0px 2px 5px #666;

    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s;
}




/* 
                   o8o                                        o8o        .o8      .   oooo        
                   `"'                                        `"'       "888    .o8   `888        
ooo. .oo.  .oo.   oooo  ooo. .oo.           oooo oooo    ooo oooo   .oooo888  .o888oo  888 .oo.   
`888P"Y88bP"Y88b  `888  `888P"Y88b           `88. `88.  .8'  `888  d88' `888    888    888P"Y88b  
 888   888   888   888   888   888  8888888   `88..]88..8'    888  888   888    888    888   888  
 888   888   888   888   888   888             `888'`888'     888  888   888    888 .  888   888  
o888o o888o o888o o888o o888o o888o             `8'  `8'     o888o `Y8bod88P"   "888" o888o o888o 
                                                                                                                                                                                                                                                                                                  
  .o        .o     .oooo.     .o                                                                  
o888      .d88    d8P'`Y8b  o888                                                                  
 888    .d'888   888    888  888  oo.ooooo.  oooo    ooo                                          
 888  .d'  888   888    888  888   888' `88b  `88b..8P'                                           
 888  88ooo888oo 888    888  888   888   888    Y888'                                             
 888       888   `88b  d88'  888   888   888  .o8"'88b                                            
o888o     o888o   `Y8bd8P'  o888o  888bod8P' o88'   888o                                          
                                   888                                                            
                                  o888o                                                           
                                                                                              
*/

@media(min-width: 1401px){
    .categories-wrapper.hp-quick-links a{
        width: calc(20% - 13px);
    }
}






/*                                                                                                                                        
8888888 8888888 8888888 8888888 8888888 8888888 8888888 8888888 8888888 8888888 
*/


/* 
  .oooooo.                                    .o8   o8o                     
 d'     `b                                   "888   `"'                     
d' .d"bd  8 ooo. .oo.  .oo.    .ooooo.   .oooo888  oooo   .oooo.            
8  8. 8  .d `888P"Y88bP"Y88b  d88' `88b d88' `888  `888  `P  )88b           
Y.  YoP"b'   888   888   888  888ooo888 888   888   888   .oP"888           
 8.      .8  888   888   888  888    .o 888   888   888  d8(  888           
  YooooooP  o888o o888o o888o `Y8bod8P' `Y8bod88P" o888o `Y888""8o          
                                                                            
                                                                            
                                                                            
  .o    .o    .oooo.     .o            .o        .o     .oooo.     .oooo.   
o888  o888   d8P'`Y8b  o888          o888      .d88    d8P'`Y8b   d8P'`Y8b  
 888   888  888    888  888           888    .d'888   888    888 888    888 
 888   888  888    888  888           888  .d'  888   888    888 888    888 
 888   888  888    888  888  8888888  888  88ooo888oo 888    888 888    888 
 888   888  `88b  d88'  888           888       888   `88b  d88' `88b  d88' 
o888o o888o  `Y8bd8P'  o888o         o888o     o888o   `Y8bd8P'   `Y8bd8P'                                                                                                                                                                                                                                                                               
*/


@media(min-width: 1101px) and (max-width: 1400px){
    .categories-wrapper.hp-quick-links a{
        width: calc(25% - 12px);
    }
}



/*                                                                                                                                        
8888888 8888888 8888888 8888888 8888888 8888888 8888888 8888888 8888888 8888888 
*/




/* 
                   o8o                                        o8o        .o8      .   oooo        
                   `"'                                        `"'       "888    .o8   `888        
ooo. .oo.  .oo.   oooo  ooo. .oo.           oooo oooo    ooo oooo   .oooo888  .o888oo  888 .oo.   
`888P"Y88bP"Y88b  `888  `888P"Y88b           `88. `88.  .8'  `888  d88' `888    888    888P"Y88b  
 888   888   888   888   888   888  8888888   `88..]88..8'    888  888   888    888    888   888  
 888   888   888   888   888   888             `888'`888'     888  888   888    888 .  888   888  
o888o o888o o888o o888o o888o o888o             `8'  `8'     o888o `Y8bod88P"   "888" o888o o888o 
                                                                                                  
                                                                                                  
                                                                                                  
 ooooooooo     .ooo    .ooooo.                                                                    
d"""""""8'   .88'     888' `Y88.                                                                  
      .8'   d88'      888    888 oo.ooooo.  oooo    ooo                                           
     .8'   d888P"Ybo.  `Vbood888  888' `88b  `88b..8P'                                            
    .8'    Y88[   ]88       888'  888   888    Y888'                                              
   .8'     `Y88   88P     .88P'   888   888  .o8"'88b                                             
  .8'       `88bod8'    .oP'      888bod8P' o88'   888o                                           
                                  888                                                             
                                 o888o                                                                        
*/

@media(min-width: 769px){

    .cities-wrapper a{
        border: 2px solid black;
    }


    .categories-wrapper.hp-quick-links a{
        border: 2px solid black;
        border-radius: 2px;
        box-shadow: 6px 6px 0px 0px black;

        transition-property: all;
        transition-timing-function: cubic-bezier(.4,0,.2,1);
        transition-duration: .15s;
    }

    .categories-wrapper a:hover{
        transform: translate(1px, 1px);
    }
    
    .categories-wrapper a:active{
        transform: translate(2px, 2px);
    }

    .cities-wrapper a:hover,
    .categories-wrapper.hp-quick-links a:hover {
    transform: translate(4px, 4px);
    box-shadow: 0px 0px 0px black;
    }

    .cities-wrapper a:active,
    .categories-wrapper.hp-quick-links a:active {
    transform: translate(6px, 6px);
    }

    .hp-quick-links{
        padding-top: 0px;
        /* display: none; */
    }

    .categories-wrapper.hp-quick-links .inner-categories{
        padding: 0px 10px;
        margin-top:  0px;
    }

    .categories-wrapper.hp-quick-links a{
        border-radius: 1px;
        height: 65px;
    }

    .rotate-card{
        display: none !important;
    }
}


/*                                                                                                                                        
8888888 8888888 8888888 8888888 8888888 8888888 8888888 8888888 8888888 8888888 
*/

/* 
  .oooooo.                                    .o8   o8o                    
 d'     `b                                   "888   `"'                    
d' .d"bd  8 ooo. .oo.  .oo.    .ooooo.   .oooo888  oooo   .oooo.           
8  8. 8  .d `888P"Y88bP"Y88b  d88' `88b d88' `888  `888  `P  )88b          
Y.  YoP"b'   888   888   888  888ooo888 888   888   888   .oP"888          
 8.      .8  888   888   888  888    .o 888   888   888  d8(  888          
  YooooooP  o888o o888o o888o `Y8bod8P' `Y8bod88P" o888o `Y888""8o         
                                                                           
                                                                           
                                                                           
 ooooooooo     .ooo    .ooooo.             .o    .o    .oooo.     .oooo.   
d"""""""8'   .88'     888' `Y88.         o888  o888   d8P'`Y8b   d8P'`Y8b  
      .8'   d88'      888    888          888   888  888    888 888    888 
     .8'   d888P"Ybo.  `Vbood888          888   888  888    888 888    888 
    .8'    Y88[   ]88       888' 8888888  888   888  888    888 888    888 
   .8'     `Y88   88P     .88P'           888   888  `88b  d88' `88b  d88' 
  .8'       `88bod8'    .oP'             o888o o888o  `Y8bd8P'   `Y8bd8P'                                                                                                                                                                                                                      
*/

@media(min-width: 769px) and (max-width: 1100px){

    .categories-wrapper.hp-quick-links a{
        width: calc(25% - 12px);
    }

    .categories-wrapper.hp-quick-links a{
        font-size: 17px;
    }
}


/*                                                                                                                                        
8888888 8888888 8888888 8888888 8888888 8888888 8888888 8888888 8888888 8888888 
*/


/* 
  .oooooo.                                    .o8   o8o                   
 d'     `b                                   "888   `"'                   
d' .d"bd  8 ooo. .oo.  .oo.    .ooooo.   .oooo888  oooo   .oooo.          
8  8. 8  .d `888P"Y88bP"Y88b  d88' `88b d88' `888  `888  `P  )88b         
Y.  YoP"b'   888   888   888  888ooo888 888   888   888   .oP"888         
 8.      .8  888   888   888  888    .o 888   888   888  d8(  888         
  YooooooP  o888o o888o o888o `Y8bod8P' `Y8bod88P" o888o `Y888""8o        
                                                                          
                                                                          
                                                                          
 ooooooooo     .ooo    .ooooo.            .ooooo.     .oooo.     oooooooo 
d"""""""8'   .88'     888' `Y88.         888' `Y88. .dP""Y88b   dP""""""" 
      .8'   d88'      888    888         888    888       ]8P' d88888b.   
     .8'   d888P"Ybo.  `Vbood888          `Vbood888     .d8P'      `Y88b  
    .8'    Y88[   ]88       888' 8888888       888'   .dP'           ]88  
   .8'     `Y88   88P     .88P'              .88P'  .oP     .o o.   .88P  
  .8'       `88bod8'    .oP'               .oP'     8888888888 `8bd88P'   
*/


@media(min-width: 769px) and (max-width: 925px){

    .categories-wrapper.hp-quick-links a{
        width: calc(33.33% - 11px);
    }
}


/*                                                                                                                                        
8888888 8888888 8888888 8888888 8888888 8888888 8888888 8888888 8888888 8888888 
*/



/* 
  .oooooo.                                    .o8   o8o                                                
 d'     `b                                   "888   `"'                                                
d' .d"bd  8 ooo. .oo.  .oo.    .ooooo.   .oooo888  oooo   .oooo.                                       
8  8. 8  .d `888P"Y88bP"Y88b  d88' `88b d88' `888  `888  `P  )88b                                      
Y.  YoP"b'   888   888   888  888ooo888 888   888   888   .oP"888                                      
 8.      .8  888   888   888  888    .o 888   888   888  d8(  888                                      
  YooooooP  o888o o888o o888o `Y8bod8P' `Y8bod88P" o888o `Y888""8o                                     
                                                                                                       
                                                                                                       
                                                                                                       
 ooooooooo     .ooo    .ooooo.             .o    .oooo.     .oooo.     .oooo.                          
d"""""""8'   .88'     888' `Y88.         o888  .dP""Y88b   d8P'`Y8b   d8P'`Y8b                         
      .8'   d88'      888    888          888        ]8P' 888    888 888    888 oo.ooooo.  oooo    ooo 
     .8'   d888P"Ybo.  `Vbood888          888      .d8P'  888    888 888    888  888' `88b  `88b..8P'  
    .8'    Y88[   ]88       888' 8888888  888    .dP'     888    888 888    888  888   888    Y888'    
   .8'     `Y88   88P     .88P'           888  .oP     .o `88b  d88' `88b  d88'  888   888  .o8"'88b   
  .8'       `88bod8'    .oP'             o888o 8888888888  `Y8bd8P'   `Y8bd8P'   888bod8P' o88'   888o 
                                                                                 888                   
                                                                                o888o                                                                                                                    
*/


@media(min-width:769px) and (max-width: 1200px){
    .cities-wrapper a{
        width: calc(25% - 16px);
    }
}

/*                                                                                                                                        
8888888 8888888 8888888 8888888 8888888 8888888 8888888 8888888 8888888 8888888 
*/










.cities-wrapper a div {
    position: relative; /* Ensure text is above the gradient */
    z-index: 2; /* Ensure text is above the gradient */
}

/* Category Tags */
.categories-wrapper a{
    display: flex;
    align-items: center;
    /* font-family: "Nunito Extra Bold"; */
    font-weight: 700;
    font-size: 14px;
    text-decoration: none;
    background: white;
    color: #0F0F0F;
    border-radius: 25px;
    padding: 8px 12px;

    border-radius: 8px;

    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s;
}

.categories-wrapper .background-img{font-family: "Nunito Extra Bold";}
.categories-wrapper div{z-index: 1;}

.categories-wrapper .background-img{
    color: white;
    text-shadow: 1px 1px 0 #000, 1px 1px 5px #000;
    /* box-shadow: 0px 2px 4px #666; */
}

a.active{
    background: #0d6efd !important;
    color: white;
    box-shadow: 0px 0px 0px black !important;
    /* transform: translate(4px, 4px); */
}

.card-button{
    position: absolute;
    z-index:3;
    font-weight: bold;
    /* background: rgba(0, 0, 0, .6); */
    background: linear-gradient(180deg, rgba(0,0,0,.8), rgba(0,0,0,.4));
    color: white;
    height: 32px;
    width: 31px;
    border: 0px;
    border-radius: 3px;
    box-shadow: 0 0 5px #666;
    cursor: pointer;
    
    right: 2px;
}

.vertical .rotate-card{
    display: none;
}

.full-width .rotate-card{
    width: 32px;
    height: 32px;
}

.grid .rotate-card{
    width: 32px;
    height: 32px;
}

.full-width .vertical .close-card{
    display: block;
}

.vertical .rotate-card,
.masonry .mobile-full.horizontal .rotate-card{
    right: 2px;
    top: 2px;
}

.full-width .card.horizontal .rotate-card{
    right: 0px;
    top: 0px;
}

.grid .mobile-full.horizontal .rotate-card{
    right: 0px;
    top: 35px;
}

.card.vertical .rotate-card,
.card.vertical .card-button.close-card{
    width: 32px;
    height: 32px;
}

.card-button:hover{
    background: linear-gradient(180deg, rgba(0,0,0,.8), rgba(0,0,0,.6))
}


/* 
                   o8o                                        o8o        .o8      .   oooo        
                   `"'                                        `"'       "888    .o8   `888        
ooo. .oo.  .oo.   oooo  ooo. .oo.           oooo oooo    ooo oooo   .oooo888  .o888oo  888 .oo.   
`888P"Y88bP"Y88b  `888  `888P"Y88b           `88. `88.  .8'  `888  d88' `888    888    888P"Y88b  
 888   888   888   888   888   888  8888888   `88..]88..8'    888  888   888    888    888   888  
 888   888   888   888   888   888             `888'`888'     888  888   888    888 .  888   888  
o888o o888o o888o o888o o888o o888o             `8'  `8'     o888o `Y8bod88P"   "888" o888o o888o 
                                                                                                  
                                                                                                  
                                                                                                  
 ooooooooo     .ooo    .ooooo.                                                                    
d"""""""8'   .88'     888' `Y88.                                                                  
      .8'   d88'      888    888 oo.ooooo.  oooo    ooo                                           
     .8'   d888P"Ybo.  `Vbood888  888' `88b  `88b..8P'                                            
    .8'    Y88[   ]88       888'  888   888    Y888'                                              
   .8'     `Y88   88P     .88P'   888   888  .o8"'88b                                             
  .8'       `88bod8'    .oP'      888bod8P' o88'   888o                                           
                                  888                                                             
                                 o888o                                                                                                                                                         
*/


@media(min-width: 769px){
    
    .categories-row .inner-categories {
        column-gap: 12px;
        row-gap: 12px;
        padding: 0px 0px;
    }

    .categories-wrapper.categories-row a{
    border: 2px solid black;
    box-shadow: 6px 6px 0px 0px black;

      transition-property: all;
      transition-timing-function: cubic-bezier(.4,0,.2,1);
      transition-duration: .15s;
    }

    .categories-wrapper.categories-row a:hover{
        transform: translate(4px, 4px);
        box-shadow: 0px 0px 0px black;
    }

    .categories-wrapper.categories-row a:active{
        transform: translate(5px, 5px);
        box-shadow: 0px 0px 0px black;
    }
    
}



/* 
  .oooooo.                                    .o8   o8o                         
 d'     `b                                   "888   `"'                         
d' .d"bd  8 ooo. .oo.  .oo.    .ooooo.   .oooo888  oooo   .oooo.                
8  8. 8  .d `888P"Y88bP"Y88b  d88' `88b d88' `888  `888  `P  )88b               
Y.  YoP"b'   888   888   888  888ooo888 888   888   888   .oP"888               
 8.      .8  888   888   888  888    .o 888   888   888  d8(  888               
  YooooooP  o888o o888o o888o `Y8bod8P' `Y8bod88P" o888o `Y888""8o              
                                                                                
                                                                                
                                                                                
 ooooooooo     .ooo    .ooooo.             .o        .o         .o     .oooo.   
d"""""""8'   .88'     888' `Y88.         o888      .d88       .d88    d8P'`Y8b  
      .8'   d88'      888    888          888    .d'888     .d'888   888    888 
     .8'   d888P"Ybo.  `Vbood888          888  .d'  888   .d'  888   888    888 
    .8'    Y88[   ]88       888' 8888888  888  88ooo888oo 88ooo888oo 888    888 
   .8'     `Y88   88P     .88P'           888       888        888   `88b  d88' 
  .8'       `88bod8'    .oP'             o888o     o888o      o888o   `Y8bd8P'                                                                                                                                                                                                                                        
*/


@media(min-width:769px) and (max-width: 1440px){
    .card.normal .image-plus,
    .card.tablet-normal .image-plus{
        width: 34px;
        height: 35px;
        border-top-right-radius: 0px;
        /* background: rgba(0,0,0,0.6) !important; */
        background: linear-gradient(180deg, rgba(0,0,0,.8), rgba(0,0,0,.4));
    }

    .card.normal .image-plus:hover,
    .card.tablet-normal .image-plus:hover{
        background: linear-gradient(180deg, rgba(0,0,0,.8), rgba(0,0,0,.6));
    }

    .window-open .image-plus{
        right: 2px !important;
        height: 32px !important;
        width: 31px !important;
        border-radius: 3px;
        background: rgba(0, 0, 0, .9);
    }

}



.close-card{top: 2px}
.image-plus{top: 38px;}
.image-minus{top: 74px;}
.new-window{top: 109px;}

.normal .image-plus{top:0px; right: 0px;}
.window-open .new-window{display: none;}

.window-open .image-plus{top: 38px !important;}
.window-open .image-plus,
.window-open .image-minus,
.window-open .close-card{display: flex !important}




/* CARDS 4x4 and 5x5 GRID */
@media(min-width: 1025px){
    .normal .second-button{display: none;}

    .categories-wrapper.hp-quick-links a{
        font-size: 19px;
    }
}

/* CARDS 3x3 GRID */
@media(min-width: 769px) and (max-width: 1024px){
    .tablet-normal .image-plus{top:2px}
    .tablet-normal .second-button{display: none;}
}

/* CARDS 2x2 GRID */
@media(max-width: 768px){
    /* .mobile-normal .image-plus{top:2px} */
    .mobile-normal .second-button{display: none;}

    .mobile-full .image-plus,
    .mobile-full .image-minus,
    .mobile-full .new-window{
        display: none;
    }

    .card-button.close-card{
        top: 0px !important;
        right: 0px;
        width: 32px;
        height: 32px;
        /* border-radius: 4px; */
        border-bottom-left-radius: 4px;
        background: rgba(0, 0, 0, .6);
    }
    .card-button.image-plus{
        top: 0px !important;
        right: 0px !important;
        width: 100%;
        height: 100%;
        opacity: 0;
    }

    /* .home .cities-wrapper,
    .home-category-page .cities-wrapper{
        flex-wrap: nowrap;
        overflow-y: scroll;
        justify-content: start;
    }

    .home .cities-wrapper a,
    .home-category-page .cities-wrapper a{
        min-width: 200px;
        width: auto;
        padding: 0px 50px;
        height: 70px;
        border-radius: 0px;
        box-shadow: 0px 0px 0px black;
        border-radius: 6px;
    } */

    .home .cities-wrapper a,
    .home-category-page .cities-wrapper a{
        width: calc(50% - 3px);
    }

    .cities-wrapper a{
        font-family: "Nunito Black";
        font-size: 17px;
        /* box-shadow: 0px 3px 5px rgba(0,0,0,0.4); */
        width: auto;
        padding: 0px 20px;
        width: calc(50% - 8px);
        text-wrap: nowrap;
        word-wrap: nowrap;

        width: fit-content;
        min-width: -webkit-fit-content;

        border-radius: 6px;
    }

    /* .thailand .cities-wrapper .bangkok-img,
    .thailand .cities-wrapper .chiangmai-img,
    .thailand .cities-wrapper .phuket-img{
        width: calc(50% - 3px);
    } */

    .thailand .cities-wrapper .bangkok-img,
    .taiwan .cities-wrapper .taipei-img,
    .indonesia .cities-wrapper .jakarta-img,
    .brazil .cities-wrapper .riodejaneiro-img,
    .peru .cities-wrapper .lima-img,
    .argentina .cities-wrapper .buenosaires-img,
    .germany .cities-wrapper .berlin-img,
    .france .cities-wrapper .paris-img,
    .croatia .cities-wrapper .zagreb-img,
    .turkey  .cities-wrapper .istanbul-img,
    .uae .cities-wrapper .dubai-img,
    .israel .cities-wrapper .telaviv-img{
        width: calc(100% - 5px);
    }

    .thailand .cities-wrapper .kophangan-img{
        width: calc(50% - 8px) !important;
    }

    .continent-page .cities-wrapper a{
        width: calc(48% - 8px)      
    }

    .korea .cities-wrapper a, 
    .seoul .cities-wrapper a{
        width: calc(100% - 5px);
    }

    .bali .cities-wrapper a{
        width: calc(50% - 8px)
    }

    .japan .cities-wrapper .tokyo-img{
        width: calc(100% - 5px);
    }

    .japan .cities-wrapper .kyoto-img{
        width: calc(50% - 8px) !important;
    }

    .vietnam .cities-wrapper a{width: 100%}
    .hanoi .cities-wrapper a{width:40%}
    .hochiminhcity .cities-wrapper a{width: 60%}

    .vietnam .cities-wrapper .hanoi-img{
        /* width: 150px; */
    }

    .categories-wrapper{
        /* overflow-y: scroll; */
    }

    .home .categories-wrapper{
        /* overflow-y: scroll; */
        position: relative;
    }

    .home .categories-wrapper.categories-row{
        overflow-y: scroll;
        margin-top: 0px;
        padding-top: 5px;
        padding-bottom: 10px;
    }

    .home .categories-wrapper.categories-row .inner-categories{
        width: 1200px;
        padding-bottom: 0px;
        padding-top: 10px;
    }

    .quick-links-countries{
        padding: 0px 10px;
    }

    .categories-wrapper.categories-row a{
        /* border: 2px solid black;
        box-shadow: 6px 6px 0px 0px black; */

        background: rgba(0,0,0,0.05);

        text-wrap: nowrap;
        white-space: nowrap;

        transition-property: all;
        transition-timing-function: cubic-bezier(.4,0,.2,1);
        transition-duration: .25s;
    }

    .categories-wrapper.categories-row a:active{
        transform: translate(4px, 4px);
        box-shadow: 0px 0px 0px black;
    }

    /* .categories-wrapper .background-img{
        font-size: 15px;
        border: 0px solid black;
        background-image: none !important;
        text-shadow: none;
        color: black;
    } */
}







/* 
                                                                  o8o        .o8      .   oooo        
                                                                  `"'       "888    .o8   `888        
ooo. .oo.  .oo.    .oooo.   oooo    ooo         oooo oooo    ooo oooo   .oooo888  .o888oo  888 .oo.   
`888P"Y88bP"Y88b  `P  )88b   `88b..8P'           `88. `88.  .8'  `888  d88' `888    888    888P"Y88b  
 888   888   888   .oP"888     Y888'    8888888   `88..]88..8'    888  888   888    888    888   888  
 888   888   888  d8(  888   .o8"'88b              `888'`888'     888  888   888    888 .  888   888  
o888o o888o o888o `Y888""8o o88'   888o             `8'  `8'     o888o `Y8bod88P"   "888" o888o o888o 
                                                                                                      
                                                                                                      
                                                                                                      
  oooooooo   .oooo.     .oooo.                                                                        
 dP"""""""  d8P'`Y8b   d8P'`Y8b                                                                       
d88888b.   888    888 888    888 oo.ooooo.  oooo    ooo                                               
    `Y88b  888    888 888    888  888' `88b  `88b..8P'                                                
      ]88  888    888 888    888  888   888    Y888'                                                  
o.   .88P  `88b  d88' `88b  d88'  888   888  .o8"'88b                                                 
`8bd88P'    `Y8bd8P'   `Y8bd8P'   888bod8P' o88'   888o                                               
                                  888                                                                 
                                 o888o                                                                                                                                                                    
*/

@media(max-width: 500px){

    .categories-wrapper.hp-quick-links a{
        font-size: 19px;
    }

    .home .categories-wrapper.categories-row .inner-categories{
        width: 1300px;
    }

    .home .continents-wrapper a,
    .home-category-page .continents-wrapper a{
        width: calc(50% - 3px) !important;
    }

    .categories-wrapper a{
        /* border-radius: 6px; */
        padding: 4px 10px;

        /* border: 1px solid #ccc; */
        /* box-shadow: 0 0 5px #ccc; */
        line-height: 25px;
    }

    /* #home-categories.categories-wrapper.categories-row .background-img{
        font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 14px;
        font-weight: 600;
    }     */


    .cities-wrapper a{
        /* width: calc(50% - 2px); */
        font-size: 17px;
        height: 45px;

        /* padding: 0px 20px; */

        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;

        /* border-radius: 4px; */
        /* box-shadow: 0px 2px 5px #ccc; */
        /* white-space: nowrap; */
        /* width: 100%; */
    }

    .mexico .cities-wrapper .playadelcarmen-img div{
        white-space: wrap;
    }

    .home .cities-wrapper a{
        height: 70px;
        border-radius: 2px;
        box-shadow: 0px 0px 0px #ccc;
    }

    .home .cities-wrapper a:nth-child(3),
    .home .cities-wrapper a:nth-child(6),
    .home .cities-wrapper a:nth-child(9),
    .home .cities-wrapper a:nth-child(12),
    .home .cities-wrapper a:nth-child(15)
    {
        width: calc(100% - 4px);
    }

    .country-page .cities-wrapper a:nth-child(3),
    .country-page .cities-wrapper a:nth-child(6),
    .country-page .cities-wrapper a:nth-child(9),
    .country-page .cities-wrapper a:nth-child(12),
    .country-page .cities-wrapper a:nth-child(15)
    {
        width: calc(100% - 2px);
    }

    .city-page .cities-wrapper a:nth-child(3),
    .city-page .cities-wrapper a:nth-child(6),
    .city-page .cities-wrapper a:nth-child(9),
    .city-page .cities-wrapper a:nth-child(12),
    .city-page .cities-wrapper a:nth-child(15)
    {
        width: calc(100% - 2px);
    }

    .category-page .cities-wrapper a:nth-child(3),
    .category-page .cities-wrapper a:nth-child(6),
    .category-page .cities-wrapper a:nth-child(9),
    .category-page .cities-wrapper a:nth-child(12),
    .category-page .cities-wrapper a:nth-child(15)
    {
        width: calc(100% - 2px);
    }

    .country-category-page .cities-wrapper a:nth-child(3),
    .country-category-page .cities-wrapper a:nth-child(6),
    .country-category-page .cities-wrapper a:nth-child(9),
    .country-category-page .cities-wrapper a:nth-child(12),
    .country-category-page .cities-wrapper a:nth-child(15)
    {
        width: calc(100% - 2px);
    }

}

.card-button{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Nunito Regular";
    padding: 0px;
}

.close-card{
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
}

.image-plus:hover {transform: scale(1.04);}
.image-plus:active {transform: scale(.98);}


/* Tooltip text */

.card-button[data-tooltip] {
    font-size: 15px;
    line-height: 16px;
}

.card-button[data-tooltip]::after {
    font-family: "Nunito Regular";
    font-size: 10px;
    content: attr(data-tooltip); /* Get the tooltip text from the data-tooltip attribute */
    position: absolute;
    bottom: 125%; /* Position the tooltip above the button */
    right: 0;
    background-color: #333; /* Dark background */
    color: #fff; /* White text */
    padding: 5px 10px;
    border-radius: 4px;
    white-space: nowrap; /* Prevents text from wrapping */
    opacity: 0; /* Hide the tooltip */
    visibility: hidden; /* Make it invisible */
    transition: opacity 0.2s, visibility 0.2s; /* Smooth transition */
    pointer-events: none; /* Prevent tooltip from interfering with mouse events */
    z-index: 1; /* Ensure it appears above other elements */
    display: none;
}

/* Show the tooltip when hovering over the button */
.card-button[data-tooltip]:hover::after {
opacity: 1; /* Show the tooltip */
visibility: visible; /* Make it visible */
}

.pagination button, .back-to-top{
    font-family: "Nunito Black";
    background: black;
    color: white;
    border-radius: 6px;
}

.pagination button{
    width: 110px;
    height: 55px;
    box-shadow: 0px 0px 5px black;
    border: 0px solid black;
}

.back-to-top {
    position: fixed;
    bottom: 25px;
    right: 15px;
    width: 100px;
    height: 45px;
    z-index: 5;
    color: white;
    cursor: pointer;
    box-shadow: 0px 0px 5px black;
    border: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination .next:hover, .pagination .previous:hover, .back-to-top:hover {transform: scale(1.03);}
.pagination .next:active, .pagination .previous:active, .back-to-top:active {transform: scale(.98);}




/* Needs to stay below other button code */
@media(max-width: 768px){

    .back-to-top {
        position: fixed;
        bottom: 25px;
        right: 15px;
        width: 45px;
        height: 45px;
    }
}