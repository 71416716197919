.hp-countries-menu{
    position: relative;
    background: black;

    display: flex;
    flex-direction: column;
    align-items: start;

    width: 100%;
    padding: 20px;

    row-gap: 15px;
    z-index: 15;
    box-shadow: 0px 0px 15px black;

    border-top: 1px solid white;
    border-bottom: 2px solid white;
}

/* 
                   o8o                                        o8o        .o8      .   oooo        
                   `"'                                        `"'       "888    .o8   `888        
ooo. .oo.  .oo.   oooo  ooo. .oo.           oooo oooo    ooo oooo   .oooo888  .o888oo  888 .oo.   
`888P"Y88bP"Y88b  `888  `888P"Y88b           `88. `88.  .8'  `888  d88' `888    888    888P"Y88b  
 888   888   888   888   888   888  8888888   `88..]88..8'    888  888   888    888    888   888  
 888   888   888   888   888   888             `888'`888'     888  888   888    888 .  888   888  
o888o o888o o888o o888o o888o o888o             `8'  `8'     o888o `Y8bod88P"   "888" o888o o888o 
                                                                                                  
                                                                                                  
                                                                                                  
 ooooooooo     .ooo    .ooooo.                                                                    
d"""""""8'   .88'     888' `Y88.                                                                  
      .8'   d88'      888    888 oo.ooooo.  oooo    ooo                                           
     .8'   d888P"Ybo.  `Vbood888  888' `88b  `88b..8P'                                            
    .8'    Y88[   ]88       888'  888   888    Y888'                                              
   .8'     `Y88   88P     .88P'   888   888  .o8"'88b                                             
  .8'       `88bod8'    .oP'      888bod8P' o88'   888o                                           
                                  888                                                             
                                 o888o                                                                        
*/


@media(min-width: 769px) {  
    .black-bar-title {
      display: flex;
      justify-content: center;
  
      width: calc(100% - 20px);
      background: black;
      color: white;
  
      margin: 20px auto 20px auto;
      margin-right: auto;
      padding: 25px 0px;

      /* border: 4px solid black; */
      /* box-shadow: 6px 6px 0px 0px black; */
    }
  
    .black-bar-title h2 {
      font-size: 30px;
    }
}







.asia-dropdown-menu{
    background: black;
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: baseline;

    height: 500px;
    width: 100%;
    padding: 20px;
    text-align: left;
    column-gap: 5px;
    z-index: 15;
    box-shadow: 0px 0px 15px black;

    line-height: 18px;
    row-gap: 22px;
    flex-wrap: wrap;
    z-index: 10;
    overflow-y: scroll;

    border-top: 1px solid white;
    border-bottom: 2px solid white;
}

.asia-dropdown-menu .inner-menu{
    width: 100%;
    display: flex;
    overflow-y: scroll;
    row-gap: 15px;
}

.asia-dropdown-menu .inner-menu a{
    font-family: "Nunito Black";
    font-size: 15px;
    font-weight: bold;
    color: white;
    text-decoration: none;
    margin: 0px 7px;
    cursor: pointer;
}

.dropdownMenuBack{
    display: flex;
    align-items: center;
    color: white;
    font-family: "Nunito Black";
    font-size: 15px;
}